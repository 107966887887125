import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_644dd6b114c10ef995d1ca9daefe8dd2/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_644dd6b114c10ef995d1ca9daefe8dd2/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_644dd6b114c10ef995d1ca9daefe8dd2/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_644dd6b114c10ef995d1ca9daefe8dd2/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_644dd6b114c10ef995d1ca9daefe8dd2/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_644dd6b114c10ef995d1ca9daefe8dd2/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_644dd6b114c10ef995d1ca9daefe8dd2/node_modules/next/dist/lib/metadata/metadata-boundary.js");
